import { BookingState } from "src/app/services/event.service";

import { Config } from "../../interfaces/config";
import { EventType } from "../event/event-type.enum";
import { Event } from '../event/event.interface';
import { FeriproGender } from "../event/feripro-gender.enum";
import { Timespan } from "../event/timespan.interface";

import { NumberRange } from "./number-range.interface";

const MAXIMUM_FILTER_AGE = 99;

export class FilterOptions {
  /** ACTIVATED FILTERS */
  category_tags: string[]; // filter tags that can be added to events (1. Ferienwoche)
  special_tags: string[];
  period_types: EventType[]; // filter for type of events ("Tagesveranstaltung", "Wochenveranstaltung")
  gender: FeriproGender[]; // gender [w, m, d] (empty ^= no filter applied)
  booking_states: BookingState[]; // true -> only show events with free places
  only_handicapped_accessible: boolean; // true -> only show events which are appropriate for handicapped
  date_filter_active: boolean; // true -> filter by date is active
  date_range: Timespan<string>;
  price_filter_active: boolean; // true -> filter by price is active
  price_range: NumberRange;
  age_range: NumberRange;
  search_text: string; // text to search in events
  organizers: Event["organizer"][];

  // Presets
  constructor(config_data: Config) {
    this.search_text = null;
    this.category_tags = [];
    this.special_tags = [];
    this.gender = [];
    this.period_types = [];
    this.organizers = [];
    this.booking_states = config_data.only_display_events_with_free_places ? [BookingState.FREE] :  [];;
    this.only_handicapped_accessible = false;
    this.date_filter_active = false;
    this.price_filter_active = false;
    this.date_range = {
      start: null,
      end: null,
    };

    this.init_age_range(config_data);
    this.init_price_range(config_data);
  }

  public init_age_range(config: Config): void {
    this.init_range(config, "age_range", { lower: 0, upper: MAXIMUM_FILTER_AGE });
  }

  public init_price_range(config: Config): void {
    this.init_range(config, "price_range", { lower: 0, upper: 1000 });
  }

  // use config_data.filter.[fieldname] (should be a NumberRange type) or respective default if not set
  private init_range(
    config_data: Config,
    fieldname: string,
    defaults: NumberRange
  ): void {
    // get values form config if they exist
    const config_range = config_data?.filter?.[fieldname];
    if (!config_range) {
      this[fieldname] = defaults;
      return;
    }

    const lower = config_range.min || defaults.lower;
    const upper = config_range.max >= lower ? config_range.max : defaults.upper;

    this[fieldname] = { lower, upper };
  }
}

import { AccountType } from "src/app/services/question.service";

import { Person } from "./person.class";

/**
 * duplicate to models/etc/child.class.ts
 */
export class Child extends Person {
  type = AccountType.CHILD;
}

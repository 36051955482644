import { AccountType } from "../../services/question.service";
import { Event } from "../event/event.interface";
import { CustomQuestion } from "../question/custom-question.interface";
import { ParticipantModel } from "../registration/participant-model.class";

export class Registration {
  event_id: number;
  name: string;
  priority: number;
  custom_questions: (CustomQuestion & { answer?: string })[];
  group_code: string;
  price: number;

  price_category: number;
  
  // added by us
  display_normalpreis?: boolean;
  groups_done?: boolean;
  price_category_is_locked?: boolean;

  static instantiate_from_event(event: Event): Registration {
    return {
      event_id: event.event_id || null,
      name: event.name || "",
      priority: 0,
      custom_questions: event.registration_custom_fields || [],
      group_code: "",
      price: event.price || 0,
      price_category: 0,
      display_normalpreis: event.display_normalpreis,
    };
  }
}

export class Reservation {
  id: number; // out of our backend
  participant: Readonly<ParticipantModel>;
  program_id: number;
  registrations: Registration[]; // erstmal leer
  response_error_message?: string;

  child_obj: number;
  other_participant: number;

  pk?: number;
  user?: number;
  type?: AccountType;

  static get_account_type(reservation: Reservation): AccountType {
    if (reservation.type) {
      return reservation.type;
    }

    const account_type: AccountType = reservation.other_participant
      ? AccountType.SECOND_LEGAL_GUARDIAN
      : undefined;
    return (reservation as any).email
      ? AccountType.OWNER
      : account_type || AccountType.CHILD;
  }
}
